import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/radu.jpg";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              LET ME <span className="purple"> INTRODUCE </span> MYSELF
            </h1>
            <p className="home-about-body">
              I've been a tinkerer with electronics and software since I was young. I remember jailbreaking my iPod Touch 2nd Gen just to be able to change the background image on the home screen!

              <br />
              <br />I am fluent in core languages like
              <i>
                <b className="purple"> C++, Javascript</b> and <b className="purple">Java</b>. 
              </i>
              <br />
              <br />
              My field of interests are 
              <i>
                <b className="purple">Web Technologies</b> and <b className="purple">Cloud Infrastructure</b> and
                also in areas related to{" "}
                <b className="purple">
                  DevOps and Continuous Integration/Development
                </b>.
              </i>
              <br />
              <br />
              Whenever possible, I also learn about how to self-host various
              services using <b className="purple">Linux</b> Cloud VPSes and developing
              front-ends using
              <i>
                <b className="purple">
                  {" "}
                  Modern Javascript Library and Frameworks
                </b>
              </i>
              &nbsp; like
              <i>
                <b className="purple"> React.js and Next.js</b>.
              </i>
            </p>
          </Col>
          <Col md={4} className="myPicture">
            <Tilt>
              <img src={myImg} className="img-fluid picture" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>FIND ME ON</h1>
            <p>
              Feel free to <span className="purple">connect </span>with me
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/radu-cernatescu"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/raducernatescu/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/radcern"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
